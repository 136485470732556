import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { Modal, Button, Text, message, TextArea } from 'tea-component'
import { Parser } from 'node-sql-parser'

export default forwardRef(({ onChange }, ref) => {
  const [visible, setVisible] = useState(false)
  const [dataSql, setDataSql] = useState('')

  const close = () => setVisible(false)

  useImperativeHandle(ref, () => ({
    open: data => {
      setDataSql('')
      setVisible(true)
    }
  }))
  function extractColumns(sql) {
    if (!sql) return
    try {
      const parser = new Parser()
      let ast = parser.astify(sql) // mysql sql grammer parsed by default
      if (!ast) return
      if (!Array.isArray(ast)) ast = [ast]
      let { create_definitions } = ast[0]
      if (!create_definitions || !create_definitions.length) return
      create_definitions = create_definitions.filter(item => item.column)
      return create_definitions.map(item => {
        const { column, definition } = item
        return {
          columnName: column.column,
          columnType: definition.dataType
        }
      })
    } catch (error) {
      message.error({
        content: 'sql错误或者尝试只粘贴字段定义部分 CREATE TABLE NAME ( )'
      })
    }
    return null
  }

  return (
    <>
      <Modal
        visible={visible}
        caption={
          <>
            <Text type='secondary'>支持以下Sql语句:</Text>
            <br></br>
            <Text
              type='primary'
              style={{ marginLeft: 5, fontSize: 12, color: '#666' }}
            >
              Athena, BigQuery, DB2, Hive, MariaDB, MySQL, PostgresQL, Redshift,
              Sqlite, TransactSQL, FlinkSQL, Snowflake(alpha), Noql
            </Text>
          </>
        }
        onClose={close}
        size={800}
      >
        <Modal.Body>
          <pre
            style={{
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
              maxHeight: '500px',
              overflowY: 'auto',
              backgroundColor: '#f3f4f7',
              padding: '10px'
            }}
          >
            <TextArea
              value={dataSql}
              style={{
                width: '100%'
              }}
              placeholder='输入create table的Sql语句'
              rows={25}
              onChange={value => {
                setDataSql(value)
              }}
            ></TextArea>
          </pre>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type='primary'
            onClick={() => {
              const columns = extractColumns(dataSql)
              if (columns) {
                onChange && onChange(columns)
                close()
              }
            }}
          >
            确定
          </Button>
          <Button
            type='weak'
            onClick={() => {
              setDataSql('')
            }}
          >
            清空
          </Button>
          <Button type='weak' onClick={close}>
            关闭
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
})
