import React, { useState, forwardRef, useImperativeHandle } from 'react'
import {
  Drawer,
  Button,
  message,
  Form,
  Input,
  InputNumber,
  Text
} from 'tea-component'
import { useForm, Controller } from 'react-hook-form'

export default forwardRef(({}, ref) => {
  const [visible, setVisible] = useState(false)
  const [dataJob, setDataJob] = useState('')
  const { control, handleSubmit, formState, reset } = useForm({ mode: 'all' })
  const close = () => setVisible(false)

  useImperativeHandle(ref, () => ({
    open: () => {
      reset({
        workSizeStr: 0,
        workCacheSizeStr: 0,
        dataSetCacheSizeStr: 0
      })
      setDataJob('')
      setVisible(true)
    }
  }))

  function copyToClipboard(text) {
    // 获取文本框元素
    const textArea = document.createElement('textarea')
    textArea.value = text
    document.body.appendChild(textArea) // 将文本框添加到页面（不可见）
    textArea.select() // 选择文本
    try {
      const successful = document.execCommand('copy') // 执行复制命令
      return successful
    } catch (err) {
      console.log('复制失败: ' + err)
    }
    document.body.removeChild(textArea) // 清理页面上的文本框
  }

  async function onSubmit(values) {
    const dataset = { ...values }
    console.log('dataset', dataset)
    const jobStr = `nohup sh startup.sh -jobConfigPath ${dataset.configPathOpStr}  -runDir ${dataset.runDir}  -workSize ${dataset.workSizeStr} -workCacheSize ${dataset.workCacheSizeStr} -jobId ${dataset.jobIdStr} -dataSetCacheSize ${dataset.dataSetCacheSizeStr}  2>&1 > mock.log &`
    setDataJob(jobStr)
    if (navigator.clipboard) {
      navigator.clipboard.writeText(jobStr)
      message.success({
        content: '复制成功!'
      })
    } else {
      if (copyToClipboard(jobStr)) {
        message.success({
          content: '复制成功!'
        })
      }
    }
  }

  function getStatus(meta) {
    if (!meta.isDirty && !formState.isSubmitted) {
      return null
    }
    return meta.invalid ? 'error' : 'success'
  }

  return (
    <>
      <Drawer
        visible={visible}
        onClose={close}
        className='job-model'
        outerClickClosable={false}
        style={{
          width: 500
        }}
        title='作业配置'
        footer={
          <>
            <Button
              type='primary'
              loading={formState.isSubmitting}
              onClick={() => {
                handleSubmit(onSubmit)()
              }}
            >
              复制启动命令
            </Button>

            <Button type='weak' onClick={close} style={{ marginLeft: '20px' }}>
              关闭
            </Button>
          </>
        }
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Form>
            <Controller
              name='configPathOpStr'
              control={control}
              rules={{
                required: '请输入Job配置文件地址'
              }}
              render={({ field, fieldState }) => (
                <Form.Item
                  label='Job配置文件地址'
                  status={getStatus(fieldState)}
                  message={fieldState.error?.message}
                >
                  <Input
                    {...field}
                    autoComplete='off'
                    maxLength={200}
                    style={{ width: '100%' }}
                    placeholder='请输入JJob配置文件地址'
                  />
                </Form.Item>
              )}
            />
            <Controller
              name='runDir'
              control={control}
              rules={{
                required: '请输入程序的工作目录'
              }}
              render={({ field, fieldState }) => (
                <Form.Item
                  label='程序的工作目录'
                  status={getStatus(fieldState)}
                  message={fieldState.error?.message}
                >
                  <Input
                    {...field}
                    autoComplete='off'
                    maxLength={200}
                    style={{ width: '100%' }}
                    placeholder='请输入程序的工作目录'
                  />
                </Form.Item>
              )}
            />
            <Controller
              name='jobIdStr'
              control={control}
              rules={{
                required: '请输入任务Id'
              }}
              render={({ field, fieldState }) => (
                <Form.Item
                  label='任务Id'
                  status={getStatus(fieldState)}
                  message={fieldState.error?.message}
                  extra='任务Id,如果是上次的任务id会接着上次的任务重新运行'
                >
                  <Input
                    {...field}
                    autoComplete='off'
                    maxLength={200}
                    style={{ width: '100%' }}
                    placeholder='请输入任务Id'
                  />
                </Form.Item>
              )}
            />

            <Controller
              name='workSizeStr'
              control={control}
              rules={{
                required: '请输入同时工作的线程数'
              }}
              render={({ field, fieldState }) => (
                <Form.Item
                  label='同时工作的线程数'
                  status={getStatus(fieldState)}
                  message={fieldState.error?.message}
                >
                  <InputNumber
                    {...field}
                    placeholder='请输入同时工作的线程数'
                    min={0}
                    inputProps={{ style: { width: '82px' } }}
                  />
                </Form.Item>
              )}
            />

            <Controller
              name='workCacheSizeStr'
              control={control}
              rules={{
                required: '请输入worker缓存数'
              }}
              render={({ field, fieldState }) => (
                <Form.Item
                  label='worker缓存数'
                  status={getStatus(fieldState)}
                  message={fieldState.error?.message}
                >
                  <InputNumber
                    {...field}
                    placeholder='请输入worker缓存数'
                    min={0}
                    inputProps={{ style: { width: '82px' } }}
                  />
                </Form.Item>
              )}
            />
            <Controller
              name='dataSetCacheSizeStr'
              control={control}
              rules={{
                required: '请输入数据集缓存的最大个数'
              }}
              render={({ field, fieldState }) => (
                <Form.Item
                  label='数据集缓存的最大个数'
                  status={getStatus(fieldState)}
                  message={fieldState.error?.message}
                >
                  <InputNumber
                    {...field}
                    placeholder='请输入数据集缓存的最大个数'
                    min={0}
                    inputProps={{ style: { width: '82px' } }}
                  />
                </Form.Item>
              )}
            />
          </Form>
        </form>
        {dataJob && (
          <Text
            style={{
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
              backgroundColor: '#f3f4f7',
              padding: '10px',
              display: 'block',
              marginTop: '10px'
            }}
            copyable
          >
            {dataJob}
          </Text>
        )}
      </Drawer>
    </>
  )
})
